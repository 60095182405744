<template>
  
</template>

<script>
import axios from 'axios'; // https://github.com/axios/axios
import { mixin as mixinHelperUtils, mixinAuth } from '@/common/helperUtils';

export default {
  name: 'KakaoLogin',
  mixins: [mixinHelperUtils, mixinAuth],
  data() {
    return {
      type: null,
      code: null
    }
  },
  computed: {
  },
  created() {
    this.type = this.$route.query.type
    this.code = this.$route.query.code
  },
  async mounted() {
    if (Kakao.isInitialized()) {
      const headers = {
        'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8'
      }

      axios.defaults.headers.post = null
      const params = new URLSearchParams();
      params.append('grant_type', 'authorization_code');
      params.append('client_id', process.env.VUE_APP_KAKAO_REST_ID);
      params.append('redirect_uri', `${window.location.protocol}//${window.location.host}/oauth/kakao?type=${this.type}`);
      params.append('client_secret', process.env.VUE_APP_KAKAO_SECRET_ID);
      params.append('code', this.code);
      const response = await axios.post('https://kauth.kakao.com/oauth/token', params, {headers})
      if (response.status == 200) {
        Kakao.Auth.setAccessToken(response.data.access_token)
        const kakaoInfo = await Kakao.API.request({url: '/v2/user/me'})
        const userInfo = {
          email: kakaoInfo.kakao_account.email,
          name: kakaoInfo.kakao_account.profile.nickname,
          snsName: this.$t('sns.kakao'),
          apply_path: '1106',
          access_token: response.data.access_token,
        };
        /**
         * 인증 이벤트
         */
        if (this.type === 'signIn') {
          this.signIn(userInfo);
        } else if (this.type === 'signUp') {
          this.signUp(userInfo);
        }
      }
    }
  },
  methods: {
  },
};
</script>

<style scoped>
</style>
